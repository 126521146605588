$laptop: '(max-width: 1690px)';
$laptop_l: '(max-width: 1440px)';
$laptop_m: '(max-width: 1366px)';
$laptop_m_two: '(max-width: 1280px)';
$laptop_m_three: '(max-width: 1200px)';
$laptop_m_four: '(max-width: 1199px)';
$laptop_m_five: '(max-width: 1024px)';
$laptop_m_six: '(max-width: 1080px)';
$laptop_m_seven: '(max-width: 1240px)';
$laptop_m_eight: '(max-width: 1460px)';
$laptop_md_sd: 'only screen and (max-width: 1198px) and (min-width: 320px)';
$laptop_ls: 'only screen and (max-width: 1279px) and (min-width: 320px)';
$laptop_lm: 'only screen and (max-width: 1279px) and (min-width: 991px)';
$medium_device: 'only screen and (min-width: 992px) and (max-width: 1200px)';
$ms_device: 'only screen and (max-width: 1200px) and (min-width: 320px)';
$tab_device: 'only screen and (min-width: 768px) and (max-width: 991px)';
$tab_device_two: '(max-width: 768px)';
$tab_device-min-width: '(min-width: 768px)';
$tab_device_three: '(max-width: 980px)';
$tab_device_four: '(max-width: 750px)';
$tab_device_five: '(max-width: 736px)';
$tab_device_six: '(max-width: 720px)';
$tab_device_seven: '(min-width: 991px)';
$large_mobile: 'only screen and (min-width: 480px) and (max-width: 767px)';
$large_mobile_two: ' (max-width: 600px)';
$large_mobile_three: ' (max-width: 480px)';
$large_mobile_four: ' (max-width: 640px)';
$large_mobile_five: ' (max-width: 575px)';
$large_mobile_six: ' (max-width: 540px)';
$small_mobile: 'only screen and (min-width: 320px) and (max-width: 479px)';
$small_mobile_four: '(max-width: 425px)';
$small_mobile_three: '(max-width: 400px)';
$small_mobile_five: '(max-width: 375px)';
$small_mobile_six: '(max-width: 390px)';
$small_mobile_two: '(max-width: 320px)';

$tab_one: '(max-width: 930px)';
$tab_two: '(max-width: 950px)';

$extra-device-l: '(max-width: 1810px)';
$extra-device-2-l: '(max-width: 1700px)';
$extra-device: '(max-width: 1579px)';
$extra-device-2: '(max-width: 1468px)';
$extra-device-s: '(max-width: 520px)';
$extra-device-m: '(max-width: 599px)';
$extra-device-sm: '(max-width: 384px)';
$extra-device-sm2: '(max-width: 360px)';